// 面经
import request from "../utils/request";

// 获取面经详情
export async function getInterviewExpDetail(id) {
    try {
        return await request({
            url: `/interviewExperienceFeel/${id}`,
            method: 'get'
        });
    } catch (error) {
        return {};
    }

}
// 发布面经详情评论
export async function publishInterviewExpDetailComment(data) {
    try {
        return await request({
            url: `/publicComment/comment`,
            method: 'post',
            data
        });
    } catch (error) {
        return {};
    }

}