<template>
  <!-- 经感详情 -->
  <div id="app" style="background: #f4f4f4">
    <div class="cson-container">
      <div class="company-warp" style="padding-top: 20px">
        <div class="clearfix">
          <div class="ms-content">
            <div class="ms-cells">
              <div class="ms-cell-interview">
                <div class="ms-cell-hd">
                  <div class="ms-cell-tt">
                    {{ interviewDetail.title }}
                  </div>
                </div>

                <div class="ms-cell-ft">
                  <div class="ms-user" v-if="interviewDetail.avatar">
                    <div class="ms-user-avatar">
                      <img :src="interviewDetail.avatar" />
                    </div>
                    <div class="ms-user-name" v-if="interviewDetail.username">
                      {{ interviewDetail.username }}
                    </div>
                    <div class="ms-user-time">
                      发表于{{ interviewDetail.createTime }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="ms-group">
                <div class="item" v-html="interviewDetail.content"></div>
                <div class="ms-answer">
                  <div class="ms-cell-data">
                    <el-button type="text" size="mini" style="cursor: inherit"
                      ><span class="iconfont icon-liulan"></span
                      >{{ interviewDetail.pageViews }}
                    </el-button>
                    <el-button type="text" size="mini" @click="likeComments(0)"
                      ><span class="iconfont icon-dianzan"></span
                      >{{ interviewDetail.likeNums }}</el-button
                    >
                  </div>
                </div>
              </div>

              <div class="commentArea yours" style="position: relative">
                <div
                  style="
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    text-align: center;
                    line-height: 230px;
                    z-index: 99999;
                    background: rgba(0, 0, 0, 0.2);
                    color: #fff;
                  "
                  v-if="!userInfo || !userInfo.id"
                >
                  请先
                  <router-link to="/login">
                    <el-button type="primary" size="mini" style="margin: 8px"
                      >登录</el-button
                    ></router-link
                  >后发表评论
                </div>
                <el-row>
                  <el-col :span="2">
                    <img
                      class="userInfo avatar"
                      v-if="userInfo && userInfo.id"
                      :src="userInfo.avatar"
                      alt=""
                    />
                    <img
                      class="userInfo avatar"
                      v-else
                      src="@/assets/company/userLogo.png"
                      alt=""
                    />
                  </el-col>
                  <el-col :span="22">
                    <el-row>
                      <el-input
                        type="textarea"
                        v-model="comments"
                        :rows="3"
                        placeholder="写下你的评论..."
                        ref="activityCom"
                      >
                      </el-input>
                    </el-row>
                    <el-row style="margin-top: 12px">
                      <el-col :span="6">
                        <emotions @chooseEmotion="handleEmotion"></emotions>
                      </el-col>
                      <el-col :offset="14" :span="4" style="text-align: right">
                        <el-button
                          type="primary"
                          style="padding: 4px"
                          @click="publishInterviewExpDetailComment"
                          :disabled="!userInfo || !userInfo.id"
                          >发表评论</el-button
                        >
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </div>
              <comments
                v-if="
                  interviewDetail.commentNums ||
                  interviewDetail.commentNums == 0
                "
                :type="5"
                :comment-total="interviewDetail.commentNums"
              ></comments>
            </div>
          </div>
          <div class="ms-aside">
            <SideAdvertisement></SideAdvertisement>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import emotions from "@/utils/emotions/index";
import comments from "@/components/comments/index";
import SideAdvertisement from "@/components/sideAdvertisement";
import {
  getInterviewExpDetail,
  publishInterviewExpDetailComment,
} from "@/service/interviewExp";
export default {
  name: "interviewDetail",
  data() {
    return {
      comments: "",
      currentPage: 1,
      interviewDetail: {},
    };
  },
  components: { emotions, comments, SideAdvertisement },
  methods: {
    handleEmotion(emotion) {
      this.comments = this.comments + emotion;
    },
    getInterviewExpDetail() {
      getInterviewExpDetail(this.$route.query.id).then((res) => {
        if (res.success) {
          this.interviewDetail = res.result;
          document.title = `${this.interviewDetail.title} - 专注北美算法面试 - CSON`;
        }
      });
    },
    publishInterviewExpDetailComment() {
      if (!this.comments || this.comments == "") {
        return this.$message.error("评论不能为空！");
      }
      const data = {
        content: this.comments,
        type: true,
        superId: this.$route.query.id,
        parentId: 0,
        superType: 5,
      };
      publishInterviewExpDetailComment(data).then((res) => {
        if (res.success) {
          this.$message.success("发表评论成功！");
          this.getInterviewExpDetail();
          this.$bus.$emit("updateCommentList", "update");
          this.comments = "";
        }
      });
    },
    likeComments(commentId) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      const data = {
        type: false,
        parentId: commentId,
        superId: this.$route.query.id,
        superType: 5,
      };
      publishInterviewExpDetailComment(data).then((res) => {
        if (res.success) {
          this.$message.closeAll();
          this.$message.success("点赞成功！");
          this.getInterviewExpDetail();
        } else {
          this.$message.closeAll();
          this.$message.info("已点过赞");
        }
      });
    },
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  mounted() {
    this.getInterviewExpDetail();
  },
  watch: {
    $route() {
      this.getInterviewExpDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.orange {
  color: #fa6400 !important;
}

.blue {
  color: #2772db !important;
}

.green {
  color: #2ec1ac !important;
}

.red {
  color: #e02020 !important;
}

.ms-content {
  width: 890px;
  float: left;
}

.ms-aside {
  width: 300px;
  float: right;
}

.aside-course {
  font-size: 0;
  margin-bottom: 12px;
}
.aside-course:hover {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
}

.aside-zx {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 22px 0;
  text-align: center;
}

.aside-zx-tt {
  font-size: 14px;
  color: #34495e;
  margin-bottom: 6px;
  text-align: center;
}

.ms-type {
  font-size: 12px;
  color: #34495e;
}

.ms-cell-interview {
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 10px;
  padding: 25px 30px 30px 50px;
}

.ms-type span {
  padding: 0;
}

.ms-type span:first-child {
  padding-left: 0;
}

.ms-user {
  display: flex;
  align-items: center;
}

.ms-user-name {
  color: #0075f6;
  font-size: 12px;
  text-decoration: underline;
  padding: 0 5px;
}

.ms-user-time {
  font-size: 12px;
  color: #000000;
  opacity: 0.5;
}

.ms-user-avatar {
  font-size: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
}

.ms-cell-hd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
}

.ms-cell-tt {
  color: #34495e;
  font-size: 18px;
  margin-bottom: 4px;
}

.ms-cell-tt .el-tag {
  border-color: #b3f2ff;
  background-color: #b3f2ff;
  font-size: 12px;
  color: #34495e;
  border-radius: 0;
  padding: 0 6px;
  margin-left: 12px;
}

.ms-cell-desc {
  font-size: 12px;
  color: #828282;
}
.ms-cell-data {
  text-align: right;
}
.ms-cell-data .el-button--text .iconfont {
  font-size: 12px;
  color: #34495e;
}

.ms-cell-data .el-button--text {
  font-size: 12px;
  color: #34495e;
  padding: 0;
}

.ms-cell-data .el-button + .el-button {
  margin-left: 20px;
}

.ms-cell-ft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.pagination-block {
  text-align: center;
  padding: 20px 0;
}

.pagination-block .el-pagination {
  display: inline-block;
  position: relative;
  padding: 0 40px;
}

.pagination-block .el-pagination /deep/ .el-pager li {
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: normal;
}

.pagination-block .el-pagination /deep/ .el-pager {
  padding: 0 30px;
}

.pagination-block .el-pagination /deep/ .first {
  font-weight: normal;
  position: absolute;
  left: 0;
}

.pagination-block .el-pagination /deep/ .last {
  font-weight: normal;
  position: absolute;
  right: 0;
}

.pagination-block .el-pagination /deep/ button,
.pagination-block .el-pagination /deep/ span {
  min-width: 36px;
  font-size: 16px;
  height: 36px;
  line-height: 36px;
}

.pagination-block .el-pagination /deep/ button {
  margin: 0 25px;
}

.ms-piece span {
  font-size: 14px;
  color: #34495e;
  padding: 0 5px;
}

.ms-piece span:first-child {
  padding-left: 0;
}

i {
  font-style: normal;
}

.breadcrumb {
  padding: 9px 0;
}

.breadcrumb .el-breadcrumb {
  font-size: 16px;
}

.ms-release {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: center;
  background-color: #fff;
  padding: 4px;
  margin-bottom: 10px;
}

.ms-release .el-button--text {
  font-size: 16px;
  color: #34495e;
}

.ms-number {
  padding: 5px 0;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  text-align: center;
  background-color: #fff;
  display: flex;
  margin-bottom: 10px;
}

.ms-number .item {
  flex: 1;
  text-align: center;
  padding: 20px 0;
  border-right: 1px dashed rgba(0, 0, 0, 0.22);
}

.ms-number .item:last-child {
  border-right: 0;
}

.ms-number-val {
  font-size: 16px;
  line-height: 26px;
  color: #fa6400;
  margin-bottom: 2px;
}

.ms-number-label {
  font-size: 12px;
  line-height: 22px;
  color: #34495e;
}

.ms-report .el-button--text {
  color: #000000;
  opacity: 0.25;
}

.ms-report {
  width: 146px;
  text-align: center;
}

.ms-group {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  padding: 28px 50px 28px 40px;
}

.ms-group .item {
  margin-bottom: 20px;
}

.ms-group-hd {
  color: #292b2d;
  font-size: 16px;
  line-height: 1;
  margin-bottom: 10px;
}

.ms-group-bd p {
  color: #34495e;
  line-height: 20px;
  white-space: pre-wrap;
  word-break: break-all;
}
.ms-answer {
  justify-content: space-between;
}
.ms-answer-cz .el-button--text {
  color: #34495e;
}

.ms-answer-cz .el-button--text .iconfont {
  font-size: 12px;
  margin-right: 5px;
}

.ms-answer-cz .el-button + .el-button {
  margin-left: 10px;
}

.ms-answer-cz {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.ms-answer-input {
  display: flex;
  align-items: center;
}

.ms-answer-avatar img {
  width: 28px;
  height: 28px;
}

.ms-answer-avatar {
  width: 28px;
  font-size: 0;
  margin-right: 8px;
}

.ms-answer-block {
  display: flex;
  align-items: center;
}

.ms-answer-block .el-button {
  border-radius: 0;
  background-color: #0075f6;
  width: 78px;
}

.ms-answer-block .el-input {
  width: 380px;
  margin-right: 4px;
}

.answer-panel-head {
  padding: 3px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.answer-panel-title {
  font-size: 14px;
  color: #34495e;
}

.answer-panel-head .el-button {
  color: #6d7278;
}

.answer-panel-head .el-button span {
  margin-left: 10px;
}

.answer-cell {
  padding: 16px 40px 10px;
  background-color: #fff;
}

.answer-cell-name {
  font-size: 12px;
  color: #34495e;
  margin-right: 18px;
}

.answer-cell-time {
  font-size: 12px;
  color: #000000;
  opacity: 0.25;
}

.answer-cell-avatar img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.answer-cell-avatar {
  font-size: 0;
  margin-right: 8px;
}

.answer-cell-hd {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.answer-cell-desc {
  color: #34495e;
  font-size: 14px;
  line-height: 20px;
}

.answer-cell-ft .el-button {
  color: #34495e;
}

.answer-cell-ft .el-button:hover {
  color: #fa6400;
  opacity: 1;
}

.user-comment-cz .el-button:hover {
  color: #fa6400;
  opacity: 1;
}

.answer-cell-ft .el-button .iconfont {
  font-size: 12px;
}

.answer-cell-ft .el-button + .el-button {
  margin-left: 20px;
}

.answer-cell-bd {
  margin-bottom: 6px;
}

.answer-cell-ft {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.box-shadow {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  margin-bottom: 10px;
}

.user-comment-name {
  color: #34495e;
  font-size: 12px;
}

.user-comment-jj {
  color: #898989;
  font-size: 12px;
}

.user-comment-desc {
  font-size: 12px;
  color: #34495e;
  padding: 10px 0;
}

.user-comment-num {
  color: #000000;
  opacity: 0.85;
  font-size: 12px;
}

.user-comment-num .el-button {
  color: #0075f6;
  opacity: 1;
  text-decoration: underline;
  padding: 4px 0;
}

.user-comment {
  padding: 0 132px 0 23px;
}

.user-comment .item {
  display: flex;
  padding: 10px 0;
  padding-left: 103px;
  border-top: 1px dashed #979797;
  position: relative;
}

.user-comment-content {
  flex: 1;
}

.user-comment-avatar {
  width: 18px;
  margin-right: 5px;
}

.user-comment-time {
  font-size: 12px;
  margin-right: 15px;
  color: #898989;
}

.user-comment-cz {
  display: flex;
  align-items: center;
}

.user-comment-cz .el-button .iconfont {
  font-size: 12px;
  margin-right: 2px;
}

.user-comment-cz .el-button {
  color: #898989;
  padding: 4px 0;
}

.user-comment-cz .el-button + .el-button {
  margin-left: 10px;
}

.user-comment-cz .el-button.reply-btn {
  color: #34495e;
  text-decoration: underline;
}

.user-comment-menu {
  position: absolute;
  right: 45px;
  top: 12px;
}

.user-comment-menu .el-button .iconfont {
  color: #d8d8d8;
}

.user-comment-menu .el-button:hover .iconfont {
  color: #fa6400;
}

.popover-report {
  width: 94px;
  border-color: transparent;
  padding: 10px 15px;
}

.block {
  padding-top: 20px;
}

.user-comment-cell-ava {
  font-size: 0;
}

.user-comment-cell-ava img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.user-comment-cell__hd {
  margin-right: 10px;
  width: 35px;
}

.user-comment-cell {
  display: flex;
}

.user-comment-cell__bd {
  flex: 1;
}

.smile-btn {
  color: rgba(0, 0, 0, 0.85);
  background: rgba(235, 236, 241, 0.6);
  border-color: rgba(235, 236, 241, 0.6);
}

.smile-btn .iconfont {
  font-size: 12px;
  margin-right: 3px;
}

.publish-btn {
  background-color: #0075f6;
}

.user-comment-btn-group {
  display: flex;
  align-items: center;
  padding: 5px 0 20px 0px;
  justify-content: space-between;
}

.zx-ewm {
  border-radius: 2px;
  border: 3px solid #0075f6;
  width: 106px;
  height: 106px;
  margin: 0 auto;
  font-size: 0;
}

.ms-answer-cz .ms-report {
  width: 65px;
}

.el-breadcrumb /deep/ .el-breadcrumb__inner {
  color: #34495e !important;
}

.el-breadcrumb /deep/ .el-breadcrumb__inner.is-link {
  font-weight: 400;
}
.popover-menu {
  min-width: 95px;
  margin-top: 0 !important;
}

.popover-menu .popper__arrow {
  display: none;
}
.commentArea,
.commentsBelow {
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  background: #fff;
  border-radius: 4px;
  width: 100%;
  padding: 30px 48px;
  margin: 12px 0;
}
.userInfo.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.noComments {
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
}
.team-cell-desc {
  font-size: 14px;
  color: rgba(52, 73, 94, 0.7);
  line-height: 20px;
  margin-bottom: 4px;
  padding-left: 44px;
}

.team-cell-desc .el-link {
  margin-left: 30px;
}

.team-cell .el-link {
  color: #fa6400;
}

.team-cell__bd {
  position: relative;
}

.team-cell-info .comment-user {
  color: #34495e;
  padding-left: 44px;
}
.childComment .team-cell-info .comment-user {
  color: #34495e;
  padding-left: 30px;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 15px;
  cursor: pointer;
  vertical-align: top;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}

.team-cell__footer {
  padding: 15px 20px 0;
}

.team-cell__ed {
  float: left;
}

.team-cell__block {
  position: relative;
  margin-left: 44px;
}

.team-cell__bts {
  text-align: right;
  padding: 10px 0;
}

.team-cell__bts .btn {
  font-size: 14px;
  border-radius: 2px;
  width: 64px;
  line-height: 28px;
  border-color: transparent;
  margin-left: 10px;
}
.panel-title {
  position: relative;
  font-weight: 500;
  color: #34495e;
  font-size: 14px;
  padding-left: 11px;
  line-height: 20px;
}

.panel-title::before {
  position: absolute;
  content: "";
  width: 3px;
  height: 20px;
  background: #fa6400;
  border-radius: 2px;
  left: 0;
  top: 0;
}
.team-cell-info .comment-user {
  color: #34495e;
}

.team-cell-info .comment-user .comment-number {
  color: #34495e;
  opacity: 0.5;
  margin-left: 25px;
  font-size: 12px;
  display: inline-block;
}

.team-cell-info .comment-user .comment-number .iconfont {
  font-size: 12px;
}
.team-cell:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.team-cell {
  padding: 24px 0;
}
.secondCommentList {
  width: 95%;
  margin: 12px 0;
  margin-left: 5%;
}
.childComment {
  padding: 6px;
  line-height: 24px;
  .avatar {
    width: 24px;
    height: 24px;
    margin-right: 6px;
    border-radius: 50%;
    vertical-align: top;
  }
  .commentContent {
    color: #222;
    margin-top: 4px;
  }
}

.aside-zx {
  background: #ffffff;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-top: 10px;
  padding: 22px 0;
  text-align: center;
}

.aside-zx-tt {
  font-size: 14px;
  color: #34495e;
  margin-bottom: 6px;
  text-align: center;
}
.zx-ewm {
  border-radius: 2px;
  border: 3px solid #0075f6;
  width: 106px;
  height: 106px;
  margin: 0 auto;
  font-size: 0;
}
.comment-user div {
  font-size: 12px;
  line-height: 20px;
  margin-left: 12px;
  cursor: pointer;
  display: inline-block;
}
.comment-user div i {
  vertical-align: top;
}
.team-cell-title {
  color: #34495e;
  line-height: 36px;
  font-size: 16px;
  margin-bottom: 4px;
}
</style>

